<template>
  <div class="coupon-cont">
    <cube-tab-bar v-model="selectedLabel" show-slider class="taps">
      <cube-tab v-for="(item) in tabs" :label="item.label" :key="item.label">
      </cube-tab>
    </cube-tab-bar>
    <cube-tab-panels v-model="selectedLabel">
      <cube-tab-panel  label="有效合同">
        <div class="list">
          <div class="group-name">
            <div class="group-line">
              <div class="group-line-in"></div>
            </div>
            <div class="group-title">您所查询的保障服务信息如下</div>
          </div>
          <ul class="box shadow"  v-for="(item, index) in list1" :key="index">
            <li class="item">
              <div class="label">服务名称</div>
              <div class="text">{{item.policyName}}</div>
            </li>
            <li class="item">
              <div class="label">手机品牌</div>
              <div class="text">{{item.phoneBrand}}</div>
            </li>
			<li class="item" v-show="item.productPackage">
			  <div class="label">服务套餐</div>
			  <div class="text">{{item.productPackage}}</div>
			</li>
			<li class="item" v-show="item.productSign">
			  <div class="label">服务签名</div>
			  <div class="text"><a :href="item.productSign">点击查看</a></div>
			</li>
            <li class="item">
              <div class="label">生效时间</div>
              <div class="text">{{item.startTime}}</div>
            </li>
            <li class="item">
              <div class="label">失效时间</div>
              <div class="text">{{item.endTime}}</div>
            </li>
            <li class="item" v-if="item.flag != 6">
              <div class="label">操作</div>
              <div class="text">
                <cube-button v-if="item.policyStatus == 0 && item.flag == 0" class="btn" @click="goPath('/apply/dataText/' + item.policyId)">申请服务</cube-button>

                <cube-button v-if="item.policyStatus == 1 && (item.flag == 1 || item.flag == 4 || item.flag == 5)" class="btn" @click="goPath('/apply/authentication/' + item.policyId + '?type=view')">查看</cube-button>

                <cube-button style="margin-left: 20px;" v-if="item.url && item.isAHS == 1 && item.ticketStatus == 1" class="btn" @click="goLink(item.url)">立即回收</cube-button>

                <cube-button v-if="item.policyStatus == 1 && (item.flag == 3 || item.flag == 0)" class="btn" @click="goPath('/apply/authentication/' + item.policyId + '?type=edit')">修改申请</cube-button>
              </div>
            </li>
          </ul>
          <div class="nodata" v-show="list1.length == 0">无数据</div>
        </div>
      </cube-tab-panel>
      <cube-tab-panel  label="失效合同">
        <div class="list">
          <div class="group-name">
            <div class="group-line">
              <div class="group-line-in"></div>
            </div>
            <div class="group-title">您所查询的保障服务信息如下</div>
          </div>
          <ul class="box shadow"  v-for="(item, index) in list2" :key="index">
            <li class="item">
              <div class="label">服务名称</div>
              <div class="text">{{item.policyName}}</div>
            </li>
            <li class="item">
              <div class="label">手机品牌</div>
              <div class="text">{{item.phoneBrand}}</div>
            </li>
			<li class="item" v-show="item.productPackage">
			  <div class="label">服务套餐</div>
			  <div class="text">{{item.productPackage}}</div>
			</li>
			<li class="item" v-show="item.productSign">
			  <div class="label">服务签名</div>
			  <div class="text"><a :href="item.productSign">点击查看</a></div>
			</li>
            <li class="item">
              <div class="label">生效时间</div>
              <div class="text">{{item.startTime}}</div>
            </li>
            <li class="item">
              <div class="label">失效时间</div>
              <div class="text">{{item.endTime}}</div>
            </li>
            <li class="item">
              <div class="label">状态</div>
              <div class="text">
                {{item.typeName}}
              </div>
            </li>
          </ul>
          <div class="nodata" v-show="list2.length == 0">无数据</div>
        </div>
      </cube-tab-panel>
    </cube-tab-panels>
  </div>
</template>

<script>
export default {
  name: 'coupon',
  data () {
    return {
      imei: this.$route.params.imei,
      list1: [], // 有效合同
      list2: [], // 失效合同
      selectedLabel: '有效合同',
      tabs: [{
        label: '有效合同',
      }, {
        label: '失效合同',
      }]
    }
  },
  mounted () {
    this.getInfo();
    this.$cookies.set('imei', this.imei);
  },
  methods: {
    /**
     * 获取合同列表
     */
    getInfo () {
      const vm = this;
      if(! this.imei){
        this.$createToast({
          txt: '无IMEI信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        IMEL: this.imei
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/queryIMEL", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.queryImelList && response.data.body.queryImelList.length > 0){
              let list = [];
              response.data.body.queryImelList.forEach((e, i)=>{
                let obj = {...e};
                if(e.type == '8' || e.type == '9' || e.type == '10'){
                  obj.type = true;
                  if(e.type == '8'){
                    obj.typeName = '保单过期';
                  }else if(e.type == '9'){
                    obj.typeName = '保单中止';
                  }else if(e.type == '10'){
                    obj.typeName = '渠道退保';
                  }
                }else{
                  obj.type = false;
                  obj.typeName = '有效';
                }
                list.push(obj)
              })
              vm.formatData(list)
            }else{
              vm.$createToast({
                txt: '当前IMEI未查询到服务信息，请核对IMEI',
                type: 'warn'
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 合同数据处理
     */
    formatData (list) {
      this.list1 = list.filter(e => {
        if(! e.type){
          return e
        }else{
          this.list2.push(e)
        }
      })
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
    /**
     * 跳转爱回收
     */
    goLink (link) {
      window.location.href = link;
    },
  },
}
</script>

<style lang="scss" scoped>
.taps{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 90;
}
.list{
  padding-top: 126px;
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  font-size: 30px;
  border-radius: 14px;
  .item{
    display: flex;
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
      .text{
        color: #208170;
        font-weight: 700;
      }
    }
    padding: 21px 0;
    line-height: 48px;
    .label{
      padding-right: 20px;
    }
    .text{
      flex: 10;
      text-align: right;
    }
  }
}
.btn{
  display: inline-block;
  font-size: 26px;
  width: 148px;
  border-radius: 30px;
  text-align: center;
  padding: 0;
  line-height: 48px;
  background-color: #208170;
}
</style>